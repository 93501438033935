// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Set the font path
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
// @import "~@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
// @import "~@fortawesome/fontawesome-pro/scss/light.scss";
@import "~@fortawesome/fontawesome-pro/scss/duotone.scss";
// @import "~@fortawesome/fontawesome-pro/scss/brands.scss";
@import "~dropzone/dist/dropzone.css";
@import "~pikaday/css/pikaday.css";
@import "alerts";
@import "forms";
