.form-group {
    // margin-bottom: 0;
}

.label-required:after {
    content: '*';
}

.table-fit {
    width: 1px;
}

th {
    background-color: #F9FAFC;
}

.light-grey {
    background-color: #F9FAFC !important;
}